import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Card, Flex, Button } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import { useBlogAuthors } from '@helpers'



/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)

  const { photo } = data
  const { fluid } = (photo && photo.childImageSharp) || {}
  const authors = useBlogAuthors()
  return (
    <>
      <Seo title='About Us' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Design, Code & Test'
            subheader='Programming is an art as well as a science, it requires one to have a holistic knowledge of core programming concepts along with it’s allied technologies. For becoming a complete full stack developer one also needs to have a strong foundation on concepts like object-oriented programming and database design skills. We at DCT Academy believe that web development is a skill that anyone can acquire, by walking along a true and trusted path for learning. And yes of course! it requires a lot of dedication, hard work and commitment to the program.'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img fluid={fluid} />
            <Button sx={styles.button} style={{background: "darkorange", color: "black", borderColor:"darkorange"}}  as={Link} to='/contact'>Contact Us</Button>
          </Box>
          <Divider />
          <h1 style={{textAlign: "center"}}>Why choose DCT Academy?</h1>
          <Divider />
          <Flex sx={styles.grid}>
           
            {/* <Box sx={styles.column}>
              <h1>Design, Code and Test</h1>
              <Section title='Improve the Skills'>
                <Text variant='p'>
                Programming is an art as well as a science, it requires one to have a holistic knowledge of core programming concepts along with it’s allied technologies. For becoming a complete full stack developer one also needs to have a strong foundation on concepts like programming skills. We at DCT Academy believe that web development is a skill that anyone can acquire, by walking along a true and trusted path for learning. And yes of course! it requires a lot of dedication, hard work and commitment to the program.
                </Text>
                <Text variant='p'>
                 Any new Lines
                </Text>
                <Text variant='p'>
                  <ul>
                    <li>Cotidieque reformidans</li>
                    <li>Mucius sensibus</li>
                    <li>Sit primis iudicabit</li>
                  </ul>
                </Text>
                <Text variant='p'>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s. Fusce odio dolor,
                  rhoncus ac purus id, bibendum malesuada neque.
                </Text>

                <Text variant='p'>
                  At sit consul aperiri omittam ullum. Usu ut option tibique
                  maluisset, ornatus cum ad, pri tale cotidieque reformidans ut.
                </Text>
                <Text variant='p'>
                  Eum ludus iudico ne. Vel labitur habemus vituperata vix!
                </Text>
              </Section>
            </Box> */}
             
            <Box sx={styles.column}>
            <Section title='Live project based training'>
                <Card variant='paper'>
                Student will learn industry in-demand modern technlogies like JavaScript – React – Angular – Node and receive in hand practical exposure by learning to build software and deploy them.
                </Card>
              </Section>
              <Divider />
              <Section title='Placement Assistance'>
                <Card variant='paper'>
                We help those students who work hard and do well in the course. If your code & project work is completed, we will recommend your profile to companies.
                </Card>
              </Section>

            </Box>
            <Box sx={styles.column}>
             
            <Section title='Strong DCT Community'>
                <Card variant='paper'>
                We have strong community of students who believe in collaborative learning and development where we come together to perform parallel programming and build software share their Knowledge.
                </Card>
              </Section>
              <Divider />
              <Section title='Student specific training'>
                <Card variant='paper'>
                Each student will have different ability to grasp based on his or her range of experience and work. We ensure that each student will be able to study and work on assignments based on his/her understanding and experience.
                </Card>
              </Section>
              
              
            </Box>
          </Flex>
          <Divider />
          <Stack>
            <Main>
              <PageTitle
                header='Our Team'
                subheader='Students Success is Our Success.'
              />
          {authors.map(author => (
            <>
              <Divider />
              <AuthorExpanded author={author} withLink />
            </>
          ))}
          </Main>
          </Stack>
        </Main>
      </Stack>
    </>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    photo: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 500, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`
